import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [2,3,9];

export const dictionary = {
		"/(main)/(standard)": [13,[2,4]],
		"/(main)/(legal)/datenschutz": [~11,[2,3]],
		"/(main)/(standard)/erfahrungen": [~14,[2,4]],
		"/(main)/(legal)/impressum": [~12,[2,3]],
		"/(main)/(standard)/kandidaten": [15,[2,4,5]],
		"/(main)/(standard)/kandidaten/referral": [16,[2,4,5]],
		"/(main)/(standard)/karriere": [~17,[2,4]],
		"/(main)/(standard)/kontakt": [~18,[2,4]],
		"/(main)/(standard)/ratgeber/[category]": [~19,[2,4,6]],
		"/(main)/(standard)/ratgeber/[category]/[slug]": [~20,[2,4,6]],
		"/recruiting/[slug]": [~22,[9,10]],
		"/[...catch_all]": [~21,[],[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';