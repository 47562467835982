import { handleErrorWithSentry, Replay } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'
import { PUBLIC_SENTRY_DSN, PUBLIC_STAGE } from '$env/static/public'

// Only run Sentry in production
const initSentry = () => {
	if (!(import.meta.env.MODE === 'development') && PUBLIC_STAGE !== 'preview') {
		Sentry.init({
			dsn: PUBLIC_SENTRY_DSN,
			tracesSampleRate: 1.0,

			// This sets the sample rate to be 10%. You may want this to be 100% while
			// in development and sample at a lower rate in production
			replaysSessionSampleRate: 0.1,

			// If the entire session is not sampled, use the below sample rate to sample
			// sessions when an error occurs.
			replaysOnErrorSampleRate: 1.0,

			// If you don't want to use Session Replay, just remove the line below:
			integrations: [
				new Sentry.Replay({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
		})
	} else {
		console.log(`🧐 Sentry not running in ${import.meta.env.MODE} mode. STAGE is ${PUBLIC_STAGE}`)
	}
}

// Export the init function that SvelteKit expects
export const init = initSentry

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
